import React, { Suspense, useEffect, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "../src/components/ScrolltoTop/ScrollToTop"; // Import ScrollToTop component
import "./App.css";
import { Toaster } from "react-hot-toast";

// Replace static imports with lazy imports
const HomepageStatic = lazy(() =>
  import("./screens/HomepageStatic/HomepageStatic")
);
const IntainAdmin = lazy(() => import("./screens/IntainAdmin/IntainAdmin"));
const IntainMarket = lazy(() => import("./screens/IntainMarkets/IntainMarket"));
const Company = lazy(() => import("./screens/Company/Company"));
const Resource = lazy(() => import("./screens/Resource/Resource"));
const Contactus = lazy(() => import("./screens/ContactUs/Contactus"));
const News = lazy(() => import("./screens/News/News"));
const Events = lazy(() => import("./screens/Events/Events"));
const InsightOne = lazy(() =>
  import("./screens/Resource/InsightScreens/InsightOne")
);
const InsightTwo = lazy(() =>
  import("./screens/Resource/InsightScreens/InsightTwo")
);
const InsighThree = lazy(() =>
  import("./screens/Resource/InsightScreens/InsightThree")
);
const InsightFour = lazy(() =>
  import("./screens/Resource/InsightScreens/InsightFour")
);
const InsightFive = lazy(() =>
  import("./screens/Resource/InsightScreens/InsightFive")
);
const InsightSix = lazy(() =>
  import("./screens/Resource/InsightScreens/InsightSix")
);
const InsightSeven = lazy(() =>
  import("./screens/Resource/InsightScreens/InsightSeven")
);
const Login = lazy(() => import("./screens/Login/Login"));
const PrivacyPolicy = lazy(() =>
  import("./screens/Privacy Policy/privacypolicy")
);
const TermsandCondition = lazy(() =>
  import("./screens/TermsandConditions/TermsandCondition")
);
const SignUp = lazy(() => import("./screens/Login/SignUp"));
const Dashboard = lazy(() => import("./screens/Dashboard/Dashboard"));
const NewsManagment = lazy(() => import("./screens/Dashboard/NewsManagment"));
const EventsManagment = lazy(() =>
  import("./screens/Dashboard/EventsManagment")
);
const NewsDetail = lazy(() => import("./screens/News/NewsDetail"));
const EventDetail = lazy(() => import("./screens/Events/EventDetails"));

function HomeRoutes() {
  // Add state to track token
  const [hasToken, setHasToken] = React.useState(
    sessionStorage.getItem("token")
  );
  console.log({ hasToken }, "hasToken into routers");

  // Listen for token changes
  useEffect(() => {
    const checkToken = () => {
      setHasToken(!!sessionStorage.getItem("token"));
    };

    // Check when component mounts
    checkToken();

    // Listen for storage events
    window.addEventListener("storage", checkToken);

    return () => {
      window.removeEventListener("storage", checkToken);
    };
  }, []);

  return (
    <>
      <Toaster position="top-right" />
      <ScrollToTop />
      <Suspense fallback={null}>
        <Routes>
          {!hasToken && (
            <>
              <Route path="/" element={<HomepageStatic />} />
              <Route path="/signin" element={<Login />} />
              <Route path="/intain-admin" element={<IntainAdmin />} />
              <Route path="/intain-markets" element={<IntainMarket />} />
              <Route path="/company" element={<Company />} />

              <Route path="/contact-us" element={<Contactus />} />
              <Route path="/news" element={<News />} />
              <Route path="/events" element={<Events />} />
              <Route path="/resource" element={<Resource />} />
              <Route
                path="/intain-defi-transparency-trust-and-liquidity-in-that-order"
                element={<InsightOne />}
              />
              <Route
                path="/token-real-estate-booms-but-for-how-long-2"
                element={<InsightTwo />}
              />
              <Route
                path="/prime-time-for-invoice-factoring"
                element={<InsighThree />}
              />
              <Route
                path="/distributed-ledger-technology-and-the-future-of-securitization"
                element={<InsightFour />}
              />
              <Route
                path="/the-boring-blockchain-initiative"
                element={<InsightFive />}
              />
              <Route
                path="/capturing-value-on-a-permissioned-blockchain-trading-its-value-on-a-public-blockchain"
                element={<InsightSix />}
              />
              <Route
                path="/intelligent-blockchain-for-asset-securitisation"
                element={<InsightSeven />}
              />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/term-and-conditions"
                element={<TermsandCondition />}
              />
              <Route path="/news/:id" element={<NewsDetail />} />
              <Route path="/events/:id" element={<EventDetail />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/sign-up" element={<SignUp />} />
            </>
          )}
          {hasToken && (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/admin/news" element={<NewsManagment />} />
              <Route path="/admin/events" element={<EventsManagment />} />
            </>
          )}
        </Routes>
      </Suspense>
    </>
  );
}

const App = () => {
  return (
    <>
      <Router>
        <HomeRoutes />
      </Router>
    </>
  );
};

export default App;
